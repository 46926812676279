body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.actionsButton {
  border: 1px solid transparent !important;
  padding: 2px !important;
}

.actionsButton > span.MuiButton-label {
  padding: 2px;
}

.cron-learn-more {
  font-size: 90%;
  padding-top: 10px;
}

.cron-learn-more a {
  text-decoration: none;
  color: #3f51b5;
}

.autocompute-field > div > div {
  width: 246px;
}

div[role='tooltip'] {
  z-index: 999999;
}

.cron-form .MuiTextField-root,
.mall-integration-form .MuiTextField-root {
  display: block;
}

.cron-form .MuiTextField-root input,
.mall-integration-form .MuiTextField-root input {
  width: 246px;
}

.mall-integration-form .MuiTextField-root input[type='password'] {
  width: 190px;
}
